<template>
    <tr>
        <th class="p-participant-print-view__count-col p-participant-print-view__label"></th>
        <th class="p-participant-print-view__label"> {{ $t("first_name") }} </th>
        <th class="p-participant-print-view__label"> {{ $t("last_name") }} </th>
        <th class="p-participant-print-view__label"> {{ $t("gender") }} </th>
        <th class="p-participant-print-view__label"> {{ $t("birth") }} </th>
        <th class="p-participant-print-view__label"> {{ $t("nationality") }} </th>
        <template v-if="isPassportRequired">
            <th class="p-participant-print-view__label"> {{ $t('document_type') }}:  </th>
            <th class="p-participant-print-view__label"> {{ $t('document_number') }}:  </th>
            <th class="p-participant-print-view__label"> {{ $t('document_country') }} </th>
            <th class="p-participant-print-view__label"> {{ $t('document_expiration') }} </th>
            <th class="p-participant-print-view__label"> {{ $t('issued_on') }} </th>
        </template>
    </tr>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        computed: {
            ...mapState({
                isPassportRequired: state => state.isPassportRequired,
            }),
        },
    }
</script> 