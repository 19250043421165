<template>
   <!-- header -->
   <PrintViewHeader v-if="!isLoading"/>
   <!-- summary -->
   <ParticipantsSummary />

   <!-- participants -->
   <div class="p-participant-print-view__content">
      <div class="p-participant-print-view__subtitle" v-if="thereAreStudents">
         {{ $t("students") }}
      </div>

      <table class="p-participant-print-view__table">
         <TableHeader v-if="thereAreStudents"/>

         <Row
            v-for="(participant, index) in kids"
            :participantNumber="index + 1"
            :participant="participant"
            :key="participant.id"
         />
      </table>

      <div class="p-participant-print-view__subtitle h-block-margin-top" v-if="thereAreTeachers">
         {{ $t("teachers") }}
      </div>

      <table class="p-participant-print-view__table">
         <TableHeader v-if="thereAreTeachers"/>

         <Row
            v-for="(participant, index) in adults"
            :participantNumber="index + 1"
            :participant="participant"
            :key="participant.id"
         />
      </table>
   </div>
</template>

<script>
   import Row from '@/components/participants_print/Row.vue';
   import PrintViewHeader from '@/components/shared/PrintViewHeader';
   import TableHeader from '@/components/participants_print/TableHeader.vue';
   import ParticipantsSummary from '@/components/participant_list/ParticipantsSummary.vue';
   import { mapState } from 'vuex';

   export default {

      components: {
         ParticipantsSummary,
         PrintViewHeader,
         Row,
         TableHeader,
      },
      data() {},
      computed: {
         ...mapState({
            isLoading: state => state.loading.full,
            isPassportRequired: state => state.isPassportRequired,
            participants: state => state.participantList.participants,
         }),
         adults() {
            return this.participantsToDisplay.filter(p => !p.is_student);
         },
         kids() {
            return this.participantsToDisplay.filter(p => p.is_student);
         },
         participantsToDisplay() {
            return this.participants.filter(participant => this.isPartiallyCompleted(participant));   
         },
         thereAreTeachers(){
            return this.adults.length > 0;
         },
         thereAreStudents() {
            return this.kids.length > 0;
         },
      },
      methods: {
         hasSomeDocumentInfo(participant) {
            if(!this.isPassportRequired) {
               return false;
            }

            return !!participant.document_nr ||
               !!participant.document_type ||
               !!participant.document_issue_date ||
               !!participant.document_expiration_date ||
               !!participant.document_issuing_country_id;
         },
         isPartiallyCompleted(participant) {
            return !!participant.first_name ||
               !!participant.gender ||
               !!participant.last_name ||
               !!participant.birth_date ||
               this.hasSomeDocumentInfo(participant);
         },
      },
      watch: {
         isLoading() {
            setTimeout(print, 1000);
         }
      },
   }
</script>

<style lang="less">
   .p-participant-print-view {
      --count-width: 40px;
      --cell-side-padding: 10px;

      .b-header,
      .b-side-menu,
      .b-footer {
         display: none;
      }

      &__subtitle {
         margin-bottom: 10px;
         .h2-style;
         font-size: 11pt;
      }

      &__content {
         padding: 0 20pt 20pt 10pt;
      }

      &__table {
         border-collapse: collapse;
         font-size: 11pt;

         th,
         td {
            font-weight: 400;
            text-align: left;
            padding: 3px var(--cell-side-padding);
         }
      }

      &__secondary-row {
         td {
            padding: 4px 4px 4px calc( ~"var(--count-width) + 10px" );
         }
      }

      &__label {
         color: var(--color-gray);
         font-size: 7pt;
      }

      &__count-col {
         color: var(--color-ok);
         width: var(--count-width);
      }

      &__separator-row {
         height: 10px;
         border-top: solid 1px var(--color-gray-3) !important;
         line-height: 0;
         max-height: $height;
      }

      &__passports-fields {
         .h-flex-row-left-center;
         .align-items(flex-start);
      }

      &__passports-fields-item {
         margin-right: calc(~"var(--cell-side-padding) * 2");

         .p-participant-print-view__label {
            display: block;
         }
      }

      .l-page {
         padding: 0;
      }

      .b-participants-summary {
         padding: 10px;
         margin-bottom: 20px;
         box-shadow: none;
         background-color: var(--color-gray);
         position: relative;
         overflow: hidden; // needed for fake background for print

         &:before {
            // fake backgrond for print
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            z-index: 1;
            content: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='background:%23ebece9' />");
         }

         .b-summary-item {
            z-index: 2; // needed for fake background for print
         }

         &__type-wrapper {
            display: inline-block;
            margin-right: 15px;
         }
      }

      @media print {
         .b-participants-warning-message {
            display: none;
         }
      }
   }
</style>