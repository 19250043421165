<template>
     <tr class="p-participant-print-view__main-row">
        <td class="p-participant-print-view__count-col"> {{ participantNumber }} </td>
        <td><span class="h-font-bold"> {{ participant.first_name }} </span></td>
        <td><span class="h-font-bold"> {{participant.last_name}} </span></td>
        <td> {{ gender }} </td>
        <td> {{ formatDate(participant.birth_date) }}</td>
        <td> {{ getParticipantCountry(participant) }} </td>
        <template v-if="this.isPassportRequired">
            <td>
                {{ getDocumentName(participant.document_type) }}
            </td>

            <td>
                {{ participant.document_nr }}
            </td>

            <td>
                {{ getCountryById(participant.document_issuing_country_id) }}
            </td>

            <td>
                {{ formatDate(participant.document_expiration_date) }}
            </td>

            <td>
                {{ formatDate(participant.document_issue_date) }}
            </td>
        </template>
    </tr>

    <!-- allergies -->
    <tr class="p-participant-print-view__secondary-row" v-if="participant.allergies">
        <td colspan="999">
            <div class="p-participant-print-view__label"> {{ $t('has_allergies') }} </div>
            {{ participant.allergies }}
        </td>
    </tr>

    <!-- comments -->
    <tr class="p-participant-print-view__secondary-row" v-if="participant.others">
        <td colspan="999">
            <div class="p-participant-print-view__label"> {{ $t('has_comments') }} </div>
            {{ participant.others }}
        </td>
    </tr>

    <!-- separator -->
    <tr class="p-participant-print-view__separator-row">
        <td colspan="999">
            &nbsp;
        </td>
    </tr>
</template>
<script>
    import { mapState } from 'vuex';
    import dateHelper from '@/helpers/date.js';

    export default {
        props: {
            participant: {
                type: Object,
                default: () => null,
            },
            participantNumber: {
                type: Number,
                default: 1,
            },
        },
        computed: {
            ...mapState({
                countries: state => state.countries,
                isPassportRequired: state => state.isPassportRequired,
            }),
            gender() {
                switch(this.participant.gender) {
                    case 'm':
                        return this.$t('male');
                    case 'f':
                        return this.$t('female');
                    case 'o':
                        return this.$t('gender_other');
                    default:
                        return '';
                }
            },
        },
        methods: {
            getCountryById(id) {
                let countryFiltered =  this.countries.filter(
                country => country.id == id
                )
                return countryFiltered[0].name;
            },
            getParticipantCountry(participant) {
                let countryFiltered =  this.countries.filter(
                country => country.id == participant.nationality
                )
                return countryFiltered[0].name;
            },
            getDocumentName(documentType) {
                if (documentType == 1) {
                return this.$t("passport");
                } 
                if (documentType == 2) {
                return this.$t("personnel_pass");
                } 
                if (documentType == 3) {
                return this.$t("other");
                } 
            },
            formatDate(date) {
                return date ? dateHelper.format(date) : '';
            }
        }
    }
</script> 